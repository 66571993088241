import { styled } from '../stitches.config';
import { ReminderAlerts } from './ReminderAlerts';
import { SystemAlerts } from './SystemAlerts';
import * as React from 'react';

const StyledCenteredLayout = styled('main', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledAppLayout = styled('main', {
  '@bp1': {
    display: 'grid',
  },

  variants: {
    columns: {
      2: {
        gridTemplateColumns: '72px auto',
      },
      3: {
        gridTemplateColumns: '72px 320px auto',
      },
    },
  },

  defaultVariants: {
    columns: 2,
  },
});

export type layoutTypes = 'default' | 'centered';

type LayoutProps = {
  type?: layoutTypes;
  columns?: 2 | 3;
};

export const Layout: React.FC<LayoutProps> = (props) => {
  const { type = 'default', config, ...rest } = props;

  return (
    <>
      <SystemAlerts />
      <ReminderAlerts config={config} />
      {type === 'default' && <StyledAppLayout {...rest} />}
      {type === 'centered' && <StyledCenteredLayout {...rest} />}
    </>
  );
};
