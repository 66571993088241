import { PortableText, urlFor } from '../lib/sanity';
import { Text, Heading } from '@cutters-digital/wig-js';
import { styled } from '@stitches';
import * as React from 'react';
import Link from 'next/link';

const StyledUnorderedListItem = styled('li', {
  position: 'relative',
  paddingLeft: '$8',
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 12,
    top: 6,
    width: 8,
    height: 8,
    borderRadius: '$round',
    backgroundColor: '$white',
  },
});

const StyledLink = styled('a', {
  color: '$white',
  textDecoration: 'underline',
});

const NormalText = styled('div', {
  fontFamily: '"Brown-Regular", sans-serif',
  fontWeight: '100',
  paddingBottom: '2px',
  paddingTop: '2px',
});

const CtaBody = styled('p', {
  backgroundColor: '$brand',
  padding: '15px',
  borderRadius: '30px',
  color: 'black',
  fontWeight: 'bold',
  marginTop: '20px',
  marginBottom: '20px',
  textAlign: 'center',
  fontFamily: '$bold',
});

const CTA = (props) => {
  return <CtaBody>{props.value.title}</CtaBody>;
};

type Props = {
  value: any;
};

export const SanityRichText: React.FC<Props> = (props) => {
  const { value, ...rest } = props;

  const components = {
    // @ts-ignore
    block: {
      h1: ({ children }) => <Heading as="h1">{children}</Heading>,
      h2: ({ children }) => <Heading as="h2">{children}</Heading>,
      h3: ({ children }) => <Heading as="h3">{children}</Heading>,
      h4: ({ children }) => <Heading as="h4">{children}</Heading>,
      h5: ({ children }) => <Heading as="h5">{children}</Heading>,
      h6: ({ children }) => <Heading as="h6">{children}</Heading>,
      p: ({ children }) => <Text>{children}</Text>,
      normal: ({ children }) => <Text>{children}</Text>,
    },
    // @ts-ignore
    listItem: (props) => {
      switch (props.value) {
        case 'bullet':
          return (
            <StyledUnorderedListItem>
              <div>{props.children}</div>
            </StyledUnorderedListItem>
          );
        default:
          return (
            <StyledUnorderedListItem>
              <NormalText>{props.children}</NormalText>
            </StyledUnorderedListItem>
          );
      }
    },
    marks: {
      link: (props) => {
        return (
          <Link href={props.value.href || ''} passHref>
            <StyledLink>{props.children}</StyledLink>
          </Link>
        );
      },
      strong: (props) => {
        return (
          <div style={{ fontFamily: '"Brown-Regular", sans-serif', fontWeight: '900', fontSize: '17px' }}>
            {props.children}
          </div>
        );
      },
      Color: (props) => {
        return <span style={{ color: props.value?.hex }}>{props.children}</span>;
      },
    },
    types: {
      // @ts-ignore
      newsImage: (props) => {
        const width = 300;
        const height = 200;
        const { image } = props.value;
        const src = urlFor(image).fit('min').width(width).maxHeight(height).url();
        return (
          <div>
            {/* eslint-disable-next-line @next/next/no-img-element*/}
            <img src={src} alt={image.alt} />
            <Text>{image?.imageText}</Text>
          </div>
        );
      },
      newsBox: (props) => {
        return CTA(props);
      },
    },
  };

  return (
    <div {...rest}>
      <PortableText value={value} components={components} />
    </div>
  );
};
