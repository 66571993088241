import { useCurrentEmployeeWorkShifts, useCurrentSalon } from '@hooks';
import { styled } from '@stitches';
import { FC, useState, useEffect } from 'react';
import { addMinutes } from 'date-fns';
import { Modal, Button, Heading, Box } from '@cutters-digital/wig-js';
import { SanityRichText } from '@components/SanityRichText';
import { useTranslation } from 'next-i18next';
import { ReminderAlert } from '@models';
import { useAuth0 } from '@auth0/auth0-react';

const SystemAlertWrapper = styled('div', {
  paddingTop: 4,
  paddingBottom: 4,
  fontFamily: '$regular',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: '100',
  
  variants: {
    severity: {
      info: {
        color: 'black',
        backgroundColor: '$brand',
      },
      warning: {
        color: 'black',
        backgroundColor: 'orange',
      },
      error: {
        backgroundColor: 'red',
      },
    },
  },
});

export const ReminderAlerts: FC = ({ config }: { config: { reminders: ReminderAlert[] } }) => { 
  const { isAuthenticated } = useAuth0();

  const { shift, error, isLoading: shiftIsLoading } = useCurrentEmployeeWorkShifts();
  const { salon, isLoading: salonIsLoading } = useCurrentSalon();

  const isLoading = shiftIsLoading || salonIsLoading;
  
  const { t } = useTranslation('common');

  const [relevantReminders, setRelevantReminders] = useState<ReminderAlert[] | null>(null);

  const getCurrentEmployeeTimestamp = () => {
    const datestamp = new Date().toISOString().split('T')[0];
    const employeeId = shift ? shift.employeeId : '';

    return `${employeeId}-${datestamp}`;
  };

  useEffect(() => {
    // TODO: Remove (Temporary guard for CASH-handling reminder)
    if (salon?.country !== 'NO' || new Date().getHours() < 18) return false;

    if (typeof window === 'undefined') return;

    if (!isAuthenticated || !shift || isLoading || error || !config?.reminders) return;

    const reminders = config.reminders.filter((reminder) => {
      const hoursNow = new Date().getHours();
      const minutesNow = new Date().getMinutes();

      if (!reminder?._key || localStorage.getItem(reminder._key) === getCurrentEmployeeTimestamp()) return false;
      
      if (reminder.type === 'fixed' && reminder.time) {
        const reminderHours = parseInt(reminder.time.split(':')[0]);
        const reminderMinutes = parseInt(reminder.time.split(':')[1]);

        return hoursNow > reminderHours || (hoursNow === reminderHours && minutesNow >= reminderMinutes);
      }
      else if (reminder.type === 'shiftStart' && reminder.minutes && shift?.periods && shift.periods.length) {
        const shiftStart = new Date(shift.periods[0]?.from);
        if (!shiftStart) return false;

        const reminderCutoff = addMinutes(shiftStart, reminder.minutes);
        const reminderHours = reminderCutoff.getHours();
        const reminderMinutes = reminderCutoff.getMinutes();

        return hoursNow > reminderHours || (hoursNow === reminderHours && minutesNow >= reminderMinutes);

      } else if (reminder.type === 'shiftEnd' && reminder.minutes && shift?.periods && shift.periods.length) {
        const shiftEnd = new Date(shift.periods[0]?.to);
        if (!shiftEnd) return false;

        const reminderCutoff = addMinutes(shiftEnd, -reminder.minutes);
        const reminderHours = reminderCutoff.getHours();
        const reminderMinutes = reminderCutoff.getMinutes();

        return hoursNow > reminderHours || (hoursNow === reminderHours && minutesNow >= reminderMinutes);
      }

      return false;
    });

    setRelevantReminders(reminders);
  }, [config, shift, isAuthenticated, isLoading, error, salon, typeof window]);

  const onCloseReminder = (key: string) => {
    setRelevantReminders(relevantReminders.filter((reminder: ReminderAlert) => reminder._key !== key));

    localStorage.setItem(key, getCurrentEmployeeTimestamp());
  };

  const reminder = relevantReminders?.[0];

  if (!isAuthenticated || isLoading) return null;

  return (
    <Modal open={reminder}>
      {reminder && (
        <SystemAlertWrapper>
          <Heading as="h2">{reminder.title || 'Reminder'}</Heading>

          <Box css={{ background: 'transparent', color: 'white', margin: '$4 0' }}>
            <SanityRichText value={reminder.message} />
          </Box>

          <Button onClick={() => onCloseReminder(reminder._key)} type="button">
            {t('close')}
          </Button>
        </SystemAlertWrapper>
      )}
    </Modal>
  );
};